




























































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand, MyAllocation } from "@/store/models/DemandAndSupply";
import { CropVarietyStore, CropStore, DemandSupplyStore, MetaStore } from "@/store/modules";
import { MetaInfo } from "vue-meta";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { error } from "highcharts";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("demandList")}`,
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  fiscalYearId: number | null = null;
  searchCode: string | null = null;
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  pageNum: number = 1;
  loading: boolean = false;
  language: string = "en";
  dialogFormVisible: boolean = false;
  form: any = "";
  myAllocation: MyAllocation = {} as MyAllocation;
  salesBillDetail: MyAllocation = {} as MyAllocation;
  salesBill: File[] = [] as File[];
  sold_quantity: number | string = "";

  get dataList() {
    console.log("dl->", DemandSupplyStore.MyAllocation);
    return DemandSupplyStore.MyAllocation;
  }

  get FiscalYearList() {
    return MetaStore.FiscalYear;
  }

  get mobileScreen() {
    return MetaStore.mobileScreen;
  }

  async uploadDocument(id: number) {
    console.log(id);
  }

  toggleDialog(salesBill: MyAllocation) {
    this.salesBillDetail = salesBill;
    this.dialogFormVisible = true;
  }

  cancelSubmit() {
    this.myAllocation = {} as MyAllocation;
    this.salesBill = [] as File[];
    this.sold_quantity = "";
    (this.$refs.upload as any).clearFiles();
    this.$validator.reset();
    this.dialogFormVisible = false;
  }

  async patchSalesBill() {
    const isValid = await this.$validator.validateAll("sales-bill-form");
    console.log("isValid", isValid);
    if (isValid) {
      let salesBillForm = new FormData();
      let error = false;
      for (let file of this.salesBill) {
        salesBillForm.append("seed_sale_bill", file);
      }
      salesBillForm.append("sold_quantity", `${this.sold_quantity}`);

      await DemandSupplyStore.patchMyAllocation({
        id: this.salesBillDetail.id,
        doc: salesBillForm
      }).catch(err => {
        console.log(error);
        error = true;
      });
      this.dialogFormVisible = false;

      if (error == false) {
        this.myAllocation = {} as MyAllocation;
        this.salesBill = [];
        this.sold_quantity = "";
        this.$notify.success("Sucessfully uploaded Document");
        (this.$refs.upload as any).clearFiles();
        this.$validator.reset();
      } else {
        this.$notify.error("There was an error processing the request.");
      }
      await DemandSupplyStore.getMyAllocation();
    }
  }

  handleAfterUploadSeedSaleBill(file: any, fileList: any[]) {
    this.salesBill = [];
    this.salesBill.push(file.raw);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  search() {
    let query: any = { page: 1 };
    this.pageNum = 1;

    // query = this.routerReplacer();
    // DemandSupplyStore.getAllSupplyCluster(query);
    this.loading = false;
  }

  routerReplacer() {
    let query: any = {};
    if (this.pageNum) {
      query["page"] = String(this.pageNum);
    }
    if (this.fiscalYearId != null) {
      query["fiscalYearId"] = String(this.fiscalYearId);
    }
    if (this.searchCode != null) {
      query["searchCode"] = String(this.searchCode);
    }

    query["type"] = "demand";

    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: query
        })
        .catch(error => {});
    }
    query["lang"] = this.$i18n.locale;
    return query;
  }

  async handlePageChange(val: number) {
    this.pageNum = val;
    this.loading = true;
    let query = this.routerReplacer();

    await DemandSupplyStore.getMyAllocation(query);
    this.loading = false;
  }

  indexMethod(index: number) {
    let i = (this.dataList.page - 1) * this.dataList.page_size + index + 1;
    return this.nepaliUnicodeNumber(i);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  clearSearch() {
    let query: any = { page: 1 };
    this.pageNum = 1;
    this.fiscalYearId = null;
    this.searchCode = null;
    // query = this.routerReplacer();
    DemandSupplyStore.getAllDemandCluster(query);
  }

  demandDetail(id: number) {
    this.$router
      .push({
        name: "DemandDetail",
        params: { clusterId: String(id) }
      })
      .catch(error => {});
  }

  private async created() {
    this.language = this.$i18n.locale;

    await DemandSupplyStore.getMyAllocation();

    this.loading = true;
    await MetaStore.getAllFiscalYear();
    // await DemandSupplyStore.getAllDemandCluster(query);
    this.loading = false;
  }
}
