






































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Preferences } from "@/store/models/DemandAndSupply";
import {
  CropVarietyStore,
  CropStore,
  UserStore,
  DemandSupplyStore,
  JWTStore
} from "@/store/modules";
import DynamicField from "@/views/Admin/DynamicField.vue";
import { nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { MetaInfo } from "vue-meta";

@Component({
  components: {
    DynamicField
  },
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("myPreferences")}`,
      meta: [
        {
          name: "description",
          content: "Company User Preferences",
          vmid: "companyPreferences"
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  cropvariety = {} as CropVariety;
  preference: Preferences = {} as Preferences;
  selectedIndex = -1;
  dialogFormVisible: boolean = false;
  pageNum: number = 1;
  language: string = "";
  clearButton: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  role: string = "";

  producers = ["supplier", "source_seed_producer", "improved_seed_producer"];
  consumers = ["consumer", "improved_seed_consumer", "improved seed consumer"];
  improvedUsers = [
    "improved_seed_producer",
    "improved_seed_distributor",
    "improved_seed_consumer",
    "improved seed consumer"
  ];

  get dataList() {
    return DemandSupplyStore.Preferences;
  }

  get user() {
    return UserStore.User;
  }

  get isnotimprovedseedconsumer() {
    if (this.role == "improved seed consumer") {
      return false;
    } else {
      return true;
    }
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne" && value) {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  roleChecker(roles: String[]) {
    for (let role of roles) {
      if (this.user.role === role) {
        return true;
      }
    }
    return false;
  }

  async searchText() {
    this.loading = true;
    await CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: 1
    });
    this.loading = false;
    // CropVarietyStore.getSearchedCropValue(this.searchInput);
  }

  private get crops() {
    return CropStore.CropNameList;
  }

  private get seedTypes() {
    return DemandSupplyStore.SeedTypeList;
  }

  setValueByRole() {
    if (this.roleChecker(this.consumers)) {
      this.preference.transactiontype = "demand";
    }
    if (this.roleChecker(["source_seed_producer"])) {
      this.preference.transactiontype = "supply";
      this.preference.breeder = false;
      this.preference.foundation = false;
      this.preference.certified = false;

      this.preference.source = true;

      this.preference.labelled = false;
      this.preference.truthlabelled = false;
    }
    if (this.roleChecker(this.improvedUsers)) {
      this.preference.breeder = false;
      this.preference.foundation = false;
      this.preference.certified = false;
      if (!this.roleChecker(["improved_seed_producer"])) {
        this.preference.source = false;
      }
      this.preference.labelled = false;
      if (
        this.roleChecker([
          "improved_seed_distributor",
          "improved_seed_consumer",
          "improved seed consumer"
        ])
      ) {
        this.preference.truthlabelled = true;
      }
    }
  }

  async add() {
    let isValid = await this.$validator.validateAll();
    if (isValid) {
      this.setValueByRole();
      this.adding = true;
      let error = false;
      let preferences = await DemandSupplyStore.postPreference(this.preference).catch(err => {
        error = true;
      });
      this.adding = false;
      if (!error) {
        preferences = preferences as Preferences;
        if (!this.clearButton) {
          this.dataList.push(preferences);
          this.$notify.success(`${this.$tc("preference")} ${this.$tc("added")}`);
        } else {
          this.dataList.splice(this.selectedIndex, 1, preferences);
          this.$notify.success(`${this.$tc("preference")} ${this.$tc("edited")}`);
        }
        this.clear();
      }
    }
  }

  clear() {
    this.preference = {
      breeder: false,
      foundation: false,
      certified: false,
      source: false,
      labelled: false,
      truthlabelled: false
    } as Preferences;
    this.selectedIndex = -1;
    this.clearButton = false;
  }

  private get varieties() {
    let varieties = CropVarietyStore.CropVarieties;
    if (varieties !== undefined && this.preference.croptypeid !== undefined) {
      varieties = varieties.filter(x => x.croptypeid == this.preference.croptypeid);
    } else {
      varieties = [];
    }
    return varieties;
  }

  editForm(id: number, index: number) {
    this.selectedIndex = index;
    this.preference = Object.assign({}, this.dataList.filter(x => x.id == id)[0]);
    this.clearButton = true;
  }

  onDeleteClick(index: number, id: number) {
    let cropVariety = this.dataList.filter(x => x.id == id)[0];
    if (cropVariety != undefined) {
      let confirmMessage = `This will permanently delete the preference of crop ${cropVariety.croptypename} of variety ${cropVariety.cropvarietyname} and all related data. Do you want to continue?`;
      if (this.$i18n.locale == "ne") {
        confirmMessage = `यसले स्थायी रूपमा ${cropVariety.croptypenepname} बालीको ${cropVariety.cropvarietynepname} प्रजातिको प्राथमिकता र सबै सम्बन्धित डाटलाई मेटाउनेछ। के तपाईं मेटाउन चाहानुहुन्छ?`;
      }
      this.$confirm(confirmMessage, this.$t("warning").toString(), {
        confirmButtonText: this.$t("delete").toString(),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      })
        .then(async () => {
          await DemandSupplyStore.deletePreference(id);
          this.dataList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("cancelled").toString()
          });
        });
    }
  }

  private async created() {
    this.role = JWTStore.role;
    CropStore.clearCropNameList();
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }

    // var pagenumber = CropVarietyStore.PageNumber;
    // this.cropId = Number(this.$route.query.cropId);
    // CropVarietyStore.getAllCropValue();
    // CropVarietyStore.getPaginatedCropValue({
    //   page: this.pageNum,
    //   language: this.language
    // });
    CropStore.getAllCropName({ lang: this.$i18n.locale });
    CropVarietyStore.getAllCropVarietiesList(this.$i18n.locale);
    DemandSupplyStore.getAllSeedType();

    this.loading = true;
    await DemandSupplyStore.getPreferences();
    this.loading = false;
  }

  handlePageChange(val: number) {
    this.pageNum = val;
    // CropVarietyStore.setPageNumber(val);
    CropVarietyStore.getPaginatedCropValue({
      search: this.searchInput,
      page: val,
      language: this.language
    });
    if (this.$route.name) {
      this.$router
        .replace({
          name: this.$route.name,
          query: { page: String(val) }
        })
        .catch(error => {});
    }
  }
}
