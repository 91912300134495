


























































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { CropVarietyStore, CropStore, DemandSupplyStore } from "@/store/modules";
import { convertADtoBS, nepaliDate, nepaliUnicodeNumber } from "@/store/modules/dateConverter";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: `${this.$t("demandDetail")}`,
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Demands of seeds",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;

  get dataList() {
    return DemandSupplyStore.Demands;
  }

  indexMethod(index: number) {
    return this.nepaliUnicodeNumber(index + 1);
  }

  nepaliDate(date: string) {
    return nepaliDate(date, this.$i18n.locale);
  }

  nepaliUnicodeNumber(value: any) {
    if (this.$i18n.locale == "ne") {
      return nepaliUnicodeNumber(value);
    }
    return value;
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  private async created() {
    this.language = this.$i18n.locale;
    if (this.$route.query.page) {
      this.pageNum = Number(this.$route.query.page);
    }
    this.clusterId = Number(this.$route.params.clusterId);
    this.loading = true;
    await DemandSupplyStore.getDemandByClusterId({
      clusterId: this.clusterId,
      lang: this.$i18n.locale
    });
    this.loading = false;
    (this.$refs.dataTable as any).toggleAllSelection();
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }
}
