










































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Crop, CropVariety } from "@/store/models/cropVariety";
import { Demand } from "@/store/models/DemandAndSupply";
import { eventHub } from "@/utils/eventHub";
import {
  CropVarietyStore,
  CropStore,
  DemandSupplyStore,
  JWTStore,
  UserStore
} from "@/store/modules";
import { convertADtoBS, nepaliDate } from "@/store/modules/dateConverter";
import { MetaInfo } from "vue-meta";

@Component({
  components: {},
  metaInfo(): MetaInfo {
    return {
      title: "Distribution Detail",
      meta: [
        { name: "keyword", content: "Sqcc demand list" },
        {
          name: "description",
          content: "List of Distribution allocated to a company",
          vmid: "demandList"
        }
      ]
    };
  }
})
export default class SCMList extends Vue {
  searchInput: string = "";
  cropId: number = 0;
  supplyId: number = 0;
  pageNum: number = 1;
  clusterId: number = 0;
  language: string = "";
  loading: boolean = false;
  soldButton: boolean = false;
  multipleSelected: any = [];
  fileList: File[] = [];
  file: File = {} as File;
  dialogVisible: boolean = false;

  get dataList() {
    return DemandSupplyStore.Supply;
  }

  private get user() {
    return UserStore.User;
  }

  nepaliDate(date: string) {
    return nepaliDate(date);
  }

  thousandSeprator(amount: number) {
    if (amount == null) {
      return null;
    }
    amount = Number(amount);
    if (amount !== undefined || amount !== 0 || amount !== "0" || amount !== null) {
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return amount;
    }
  }

  canBeSelected(row: any, index: any) {
    return !row.sold;
  }

  isSupplier() {
    let userRole = this.user.role;
    let roles = ["supplier", "source_seed_producer", "improved_seed_producer"];
    for (let role of roles) {
      if (userRole === role) {
        return true;
      }
    }
    return false;
  }

  private async created() {
    if (JWTStore.loggedIn) {
      await UserStore.getUser();
    }
    if (JWTStore.loggedIn && this.isSupplier()) {
      this.language = this.$i18n.locale;
      if (this.$route.query.page) {
        this.pageNum = Number(this.$route.query.page);
      }
      if (this.$route.params.supplyId) {
        this.supplyId = Number(this.$route.params.supplyId);
      }
      this.loading = true;
      await DemandSupplyStore.getSupplyById(this.supplyId).catch(reason => {
        console.log(reason);
      });
      this.loading = false;
    } else {
      eventHub.$emit("loginClick", "companyUser", "You need to login as company user to continue");
    }
  }
  handleSelectionChange(val: any) {
    // console.log(val.length);
    if (val.length <= 0) {
      this.soldButton = false;
    } else {
      this.soldButton = true;
    }
    this.multipleSelected = val;
  }

  toogleExpand(row: any) {
    let $table = this.$refs.dataTable as any;
    $table.toggleRowExpansion(row);
  }
  batchSold() {
    this.$confirm(
      "This will mark all the selected demands as sold. This cannot be changed later.",
      "Confirm?",
      {
        confirmButtonText: "Confirm",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        for (let distribution of this.multipleSelected) {
          let formData = new FormData();
          formData.append("soldbill", this.file);
          distribution.sold = true;
          await this.submitFile(distribution.id);
          for (let key in distribution as any) {
            formData.append(key, (distribution as any)[key]);
          }
        }
        let error = false;
        await DemandSupplyStore.distributionsSold(this.multipleSelected).catch(reason => {
          error = true;
        });
        if (error) {
          for (let distribution of this.multipleSelected) {
            distribution.sold = false;
          }
        } else {
          (this.$refs.dataTable as any).clearSelection();
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  sold(distribution: any, distributionIndex: number) {
    let company = distribution.demandcompany;
    let allocatedquantity = distribution.allocatedquantity;
    let quantity = distribution.quantity;
    let id = distribution.id;
    let group = this.dataList.croptypename;
    let variety = this.dataList.cropvarietyname;
    let seedtype = this.dataList.seedtypename;
    this.$confirm(
      "This will mark the demand of " +
        company +
        " for " +
        String(allocatedquantity) +
        " kg of " +
        group +
        "'s " +
        variety +
        "'s " +
        seedtype +
        " seed as sold" +
        // " with " +
        // String(quantity) +
        // " kg" +
        ". This cannot be changed later.",
      "Confirm?",
      {
        confirmButtonText: "Confirm",
        confirmButtonClass: "el-button--danger",
        cancelButtonText: this.$t("cancel").toString(),
        showClose: false,
        type: "warning"
      }
    )
      .then(async () => {
        // let formData = new FormData();
        // formData.append("soldbill", this.file);
        this.dataList.distributionlist[distributionIndex].sold = true;

        // for (let key in this.dataList.distributionlist[distributionIndex] as any) {
        //   formData.append(key, (this.dataList.distributionlist[distributionIndex] as any)[key]);
        // }
        // this.handleRemove();
        await this.submitFile(id);
        DemandSupplyStore.distributionsSold([this.dataList.distributionlist[distributionIndex]]);
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: this.$t("cancelled").toString()
        });
      });
  }

  async submitFile(distributionid: any) {
    if (this.file && this.file.size > 0) {
      let formData = new FormData();
      formData.append("image", this.file);
      formData.append("distributionid", distributionid);
      // formData.append("username", this.username);
      // console.log(this.dataList);
      // this.dataList["bill"] = formData;
      // this.registrationCertificate.push(file);
      await DemandSupplyStore.saveDistributionSoldBill(formData);
    }
    this.handleRemove();
  }

  handleFileUpload(event: any) {
    this.fileList = [event];
    this.file = event.raw;
  }

  handleRemove() {
    this.fileList = [];
    this.file = {} as File;
  }

  viewBill() {
    this.dialogVisible = true;
  }
}
